<template>
  <div>
    <dashboard v-if="userData.type === 'manager'" />
    <app-dashboard v-if="userData.type === 'customers'" />
  </div>
</template>

<script>
// eslint-disable-next-line import/extensions
import Dashboard from '@/views/Dashboard/Index'
// eslint-disable-next-line import/extensions
import AppDashboard from '@/views/App/Dashboard/Index'

export default {
  components: {
    Dashboard,
    AppDashboard,
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
  },
}
</script>
